import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SiteNavbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import MomsMate from './pages/MomsMate';
import Contact from './pages/Contact';
import MomsMateEULA from './pages/MomsMateEULA';
import MomsMatePrivacyPolicy from './pages/MomsMatePrivacyPolicy';
import MomsMateTOS from './pages/MomsMateTOS';
import ScrollToTop from './components/ScrollToTop'
import { createTheme, NextUIProvider } from "@nextui-org/react"

const theme = createTheme({
  type: "dark",
  theme: {
    colors: {
      primaryLight: '$yellow600',
      primaryLightHover: '$yellow600',
      primaryLightActive: '$yellow500',
      primaryLightContrast: '$black',
      primary: '$yellow600',
      primaryBorder: '$yellow600',
      primaryBorderHover: '$yellow600',
      primarySolidHover: '$yellow600',
      primarySolidContrast: '$white',
      primaryShadow: '$yellow600',

      gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
      link: '$white',
      myColor: '#ff4ecd'
    },
    space: {},
    fonts: {}
  }
});

function App() {
  return (
    <NextUIProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <div className="App">
          <SiteNavbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/momsmate" element={<MomsMate />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/momsmateeula" element={<MomsMateEULA />} />
            <Route path="/momsmateprivacypolicy" element={<MomsMatePrivacyPolicy />} />
            <Route path="/momsmatetos" element={<MomsMateTOS />} />
          </Routes>
        </div>
      </Router>
    </NextUIProvider>
  );
}

export default App;

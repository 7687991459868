import { Text, Container, Spacer, Grid } from "@nextui-org/react";
import { Helmet } from "react-helmet";

const MomsMateTOS = () => (
  <>
  <Helmet>
      <title>MomsMate | YVT</title>
      <meta name="MomsMate" content="Your AI companion to product labels." />
    </Helmet>
  <Spacer y={6} />
    <Container xs>
      <Grid.Container gap={2}>
        <Grid xs={24}>
          <Text h1 align="left">MomsMate Terms of Service</Text>
        </Grid>
        <Spacer y={1} />
        <Grid xs={24} lg={14}>
          <Text align="left">
          Last Updated: June 8, 2023
<Spacer y={1} />
Welcome to MomsMate! Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the MomsMate mobile application (the "Service") operated by MomsMate ("us", "we", or "our").
<Spacer y={1} />
1. Agreement to Terms
<Spacer y={1} />
By using our Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Service.
<Spacer y={1} />
2. Use of Our Service
<Spacer y={1} />
You must be at least 18 years old to use our Service. By using our Service, you are representing that you are at least 18 years old.
<Spacer y={1} />
You may use our Service for personal, non-commercial purposes only. You may not use our Service to sell a product or service, or to increase traffic to your business for commercial reasons. If you want to do any of these things, you must get our permission first.
<Spacer y={1} />
3. Privacy
<Spacer y={1} />
Our Privacy Policy explains how we collect, use, and share your personal data when you use our Service. By using our Service, you agree to our Privacy Policy.
<Spacer y={1} />
4. Content
<Spacer y={1} />
The photos you upload to our Service are your content, not ours. However, by uploading photos, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such content in connection with providing the Service.
<Spacer y={1} />
5. Intellectual Property
<Spacer y={1} />
All content, features, and functionality of the Service, including without limitation all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof, are owned by MomsMate, its licensors, or other providers of such material and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
<Spacer y={1} />
6. Disclaimer of Warranties
<Spacer y={1} />
Our Service provides information about product labels but does not offer medical or health advice. You should not rely on the information provided by our Service as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.
<Spacer y={1} />
We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Service, or by anyone who may be informed of any of its contents.
<Spacer y={1} />
7. Changes to Terms
<Spacer y={1} />
We may modify these Terms at any time. If we do, we will post the modified Terms on the Service. You should check the Service regularly to see if any changes have been made.
<Spacer y={1} />
8. Termination
<Spacer y={1} />
We reserve the right to terminate or suspend your access to our Service, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
<Spacer y={1} />
9. Limitation of Liability
<Spacer y={1} />
In no event shall MomsMate, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from
<Spacer y={1} />
(i) your access to or use of or inability to access or use the Service;
<Spacer y={1} />
(ii) any conduct or content of any third party on the Service;
<Spacer y={1} />
(iii) any content obtained from the Service;
<Spacer y={1} />
and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
<Spacer y={1} />
10. Governing Law
<Spacer y={1} />
These Terms shall be governed and construed in accordance with the laws of Thailand, where our company is based, without regard to its conflict of law provisions.
<Spacer y={1} />
11. Contact Us
<Spacer y={1} />
If you have any questions about these Terms, please contact us at pk@yellowviking.com.
<Spacer y={1} />
By using the MomsMate Service, you are confirming that you have read, understood, and agree to our Terms of Service.

            </Text>
          </Grid>
        <Spacer y={10} />
      </Grid.Container>
    </Container>
  </>
);

export default MomsMateTOS;

import { Text, Container, Spacer, Grid , Button , Row , Link} from "@nextui-org/react";
import { Helmet } from "react-helmet";

const MomsMate = () => (
  <>
  <Helmet>
      <title>MomsMate | YVT</title>
      <meta name="MomsMate" content="Your AI companion to product labels." />
    </Helmet>
  <Spacer y={6} />
  <Container xs>
    <Grid.Container gap={2}>
      <Grid xs={24}>
        <Text h1 align="left" css={{textGradient: "45deg, $red700 -20%, $red800 100%"}}>MomsMate</Text>
      </Grid>
      <Spacer y={1} />
      <Grid xs={24} lg={14}>
        <Text align="left" size="$xl">
        is currently in beta.
        <Spacer y={2} />
        MomsMate is a practical app designed to streamline the process of checking product safety for expectant mothers. By scanning product ingredient labels, the app quickly provides information on whether the product is safe for pregnant women to use, saving you the hassle of squinting at tiny labels and searching each ingredient one by one. It's a first screening tool, making everyday decisions easier while clarifying it doesn't replace professional medical, health, or nutritional advice. A standout feature is its language versatility, capable of understanding languages beyond English, breaking down barriers and making it accessible to users worldwide. Enjoy a new level of convenience with MomsMate, your go-to guide for product safety during pregnancy.
        </Text>
      </Grid>
      <Grid xs={24} lg={14}>
      <Row justify="left">
        <Button color="primary" rounded auto ghost shadow as={Link} target="_blank" href="/momsmateprivacypolicy">
        Privacy Policy
        </Button>
        <Spacer y={2} />
        <Button color="primary" rounded auto ghost shadow as={Link} target="_blank" href="/momsmateeula">
        EULA
        </Button>
      </Row>
      </Grid>
      <Grid xs={24} lg={14}>
      <Row justify="left">
        <Button color="primary" rounded auto ghost shadow as={Link} target="_blank" href="/momsmatetos">
        Term of Service
        </Button>
      </Row>
      </Grid>
      <Spacer y={10} />
    </Grid.Container>
  </Container>
  </>
);

export default MomsMate;

import { Text, Container, Spacer, Grid } from "@nextui-org/react";
import { Helmet } from "react-helmet";

const MomsMateEULA = () => (
  <>
  <Helmet>
      <title>MomsMate | YVT</title>
      <meta name="MomsMate" content="Your AI companion to product labels." />
    </Helmet>
  <Spacer y={6} />
    <Container xs>
      <Grid.Container gap={2}>
        <Grid xs={24}>
          <Text h1 align="left">MomsMate End-User License Agreement (EULA)
</Text>
        </Grid>
        <Spacer y={1} />
        <Grid xs={24} lg={14}>
          <Text align="left">
          Last Updated: June 1, 2023
<Spacer y={1} />
This End-User License Agreement ("EULA") is a legal agreement between you ("End-User") and MomsMate ("us", "we", or "our"), for the use of the MomsMate mobile application (the "Software").
<Spacer y={1} />
Please read this EULA carefully before completing the installation process and using the Software. It provides a license to use the Software and contains warranty information and liability disclaimers.
<Spacer y={1} />
1. License Grant
<Spacer y={1} />
We grant you a personal, non-exclusive, non-transferable, limited license to download, install and use the Software solely for your personal, non-commercial purposes strictly in accordance with the terms of this EULA.
<Spacer y={1} />
2. Restrictions
<Spacer y={1} />
You agree not to, and you will not permit others to license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Software or make the Software available to any third party.
<Spacer y={1} />
3. Modifications to Software
<Spacer y={1} />
We reserve the right to modify, suspend or discontinue, temporarily or permanently, the Software or any service to which it connects, with or without notice and without liability to you.
<Spacer y={1} />
4. Term and Termination
<Spacer y={1} />
This EULA will become effective upon your acceptance of the terms and remain in effect until terminated by you or us. Your rights under this EULA will terminate immediately without notice from us if you fail to comply with any provision of this EULA.
<Spacer y={1} />
5. Severability
<Spacer y={1} />
If any provision of this EULA is held to be unenforceable or invalid, that provision will be enforced to the maximum extent possible, and the other provisions will remain in full force and effect.
<Spacer y={1} />
6. No Warranties
<Spacer y={1} />
You agree that your use of the Software is at your sole risk. The Software is provided "as is," with all faults, defects and errors, and without warranty of any kind.
<Spacer y={1} />
7. Limitation of Liability
<Spacer y={1} />
In no event will we be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Software.
<Spacer y={1} />
8. Governing Law
<Spacer y={1} />
This EULA shall be governed and construed in accordance with the laws of Thailand, where our company is based, without regard to its conflict of law provisions.
<Spacer y={1} />
9. Contact Us
<Spacer y={1} />
If you have any questions about this EULA, please contact us at pk@yellowviking.com.
<Spacer y={1} />
By installing and using the MomsMate Software, you are confirming that you have read, understood, and agree to this End-User License Agreement.</Text>
        </Grid>
        <Spacer y={10} />
      </Grid.Container>
    </Container>
  </>
);

export default MomsMateEULA;

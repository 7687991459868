import { Text, Container, Spacer, Grid , Link } from "@nextui-org/react";
import { Helmet } from "react-helmet";

const About = () => (
  <>
  <Helmet>
      <title>About | YVT</title>
      <meta name="About YVT" content="About Yellow Viking Technologies" />
    </Helmet>
  <Spacer y={6} />
    <Container xs>
      <Grid.Container gap={2}>
        <Grid xs={24}>
          <Text h1 align="left" css={{textGradient: "45deg, $yellow600 -20%, $yellow800 100%"}}>About us</Text>
        </Grid>
        <Spacer y={1} />
        <Grid xs={24} lg={14}>
          <Text align="left" size="$xl">
            Established in 2015, YVT began its journey in the app market, leaving indelible marks along the way. In October of the same year, we launched Spinology, our first mobile game. Developed under the banner of Cuckoobic, the game was built using the Unity game engine, to which we added a twist by adjusting several native physics. Spinology <Link isExternal target="_blank" color="primary" href="https://apps.apple.com/us/app/spinology-classic/id1661631428">iOS</Link> <Link isExternal target="_blank" color="primary" href="https://play.google.com/store/apps/details?id=com.bnl.spin&pli=1">Android</Link>, now published by <Link isExternal target="_blank" color="primary" href="https://botnlife.co/">Bot and Life</Link>, is a testament to our innovative spirit.
            <Spacer y={2} />
            In 2016, we conceptualized Teatastiq, ambitiously envisioning it as the "Vivino of tea". This endeavor proved challenging, especially as categorizing tea, particularly Chinese tea, was uncharted territory. Despite having a tea expert advising us, capturing the vast variety of teas was a Herculean task. The app, developed using React Native, Node.js, and image processing AI, remains unfinished and is currently housed on Heroku.
            <Spacer y={2} />
            November 2017 saw the birth of the world's first love motel review app, developed by us in a record time of one week. This achievement was made possible by leveraging the power of React Native and Ruby on Rails (RoR). Despite the app's novel concept, we decided to discontinue it in late 2021.
            <Spacer y={2} />
            As we continued our journey into 2023, we turned our innovation towards a pressing need in maternal care with the development of MomsMate. This intuitive application, which is currently awaiting approval from the App Store, harnesses the power of Optical Character Recognition (OCR) and Artificial Intelligence (AI). With a simple scan of a product label, MomsMate can accurately determine its safety for use during pregnancy. Our goal with this app is to provide peace of mind to expectant mothers, ensuring that they can easily access information about product safety right at their fingertips.
            <Spacer y={2} />
            Throughout our journey, we didn't just focus on our own ideas. We've also had the pleasure of developing several apps and websites for various clients, further enriching our experience and enhancing our capabilities in the digital space.
          </Text>
        </Grid>
        <Spacer y={10} />
      </Grid.Container>
    </Container>
  </>
);

export default About;

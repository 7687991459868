export const YVTLogo = () => (
  <svg
    className=""
    fill="none"
    height="52"
    viewBox="0 -8 36 32"
    width="52"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="var(--secondary)" height="100%" rx="16" width="100%" />
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="YVT" transform="translate(1.075000, 1.118261)" fill="#F6AD37" fill-rule="nonzero">
            <path d="M5.07774671,3.75670569 L6.69796476,0.982359721 C6.97909848,0.464481806 7.36010866,0.161153314 7.8409953,0.0723742427 C8.32188193,-0.0164048283 8.76947641,0.0649759868 9.18377875,0.316516688 C9.61287759,0.568057389 9.90880783,0.919474545 10.0715695,1.37076816 C10.2343311,1.82206177 10.1603485,2.30664753 9.84962178,2.82452544 L6.91991243,7.55201098 L6.91991243,11.4804849 C6.91991243,12.0723453 6.73865516,12.523639 6.37614062,12.8343657 C6.01362608,13.1450925 5.58822637,13.3004558 5.09994148,13.3004558 C4.59686008,13.3004558 4.1640621,13.1450925 3.80154756,12.8343657 C3.43903302,12.523639 3.25777575,12.0723453 3.25777575,11.4804849 L3.25777575,7.55201098 L0.305871643,2.82452544 C-0.00485510545,2.30664753 -0.0788376646,1.82206177 0.0839239656,1.37076816 C0.246685596,0.919474545 0.542615833,0.568057389 0.971714676,0.316516688 C1.38601701,0.0649759868 1.83361149,-0.0164048283 2.31449813,0.0723742427 C2.79538476,0.161153314 3.17639494,0.464481806 3.45752866,0.982359721 L5.07774671,3.75670569 Z" id="Path"></path>
            <path d="M18.4833864,1.2043074 C18.6905376,0.642039948 19.01976,0.279525408 19.4710536,0.116763778 C19.9223472,-0.045997852 20.3847382,-0.0385995961 20.8582266,0.138958546 C21.3021219,0.316516688 21.6424417,0.612446925 21.8791859,1.02674926 C22.1159301,1.44105159 22.1307266,1.92933648 21.9235754,2.49160393 L17.9729068,12.0797436 C17.6177905,12.8935518 17.055523,13.3004558 16.2861044,13.3004558 C15.5018893,13.3004558 14.8804358,12.8935518 14.4217439,12.0797436 L10.4710753,2.49160393 C10.2639241,1.92933648 10.2787206,1.44105159 10.5154648,1.02674926 C10.752209,0.612446925 11.0925288,0.316516688 11.5364241,0.138958546 C12.0099125,-0.0385995961 12.4723035,-0.045997852 12.9235971,0.116763778 C13.3748907,0.279525408 13.7041131,0.642039948 13.9112643,1.2043074 L16.1973254,7.06372609 L18.4833864,1.2043074 Z" id="Path"></path>
            <path d="M28.9149273,3.71231615 L28.9149273,11.4582901 C28.9149273,12.0501506 28.73367,12.5014442 28.3711555,12.8121709 C28.0086409,13.1228977 27.575843,13.2782611 27.0727616,13.2782611 C26.5844767,13.2782611 26.159077,13.1228977 25.7965624,12.8121709 C25.4340479,12.5014442 25.2527906,12.0501506 25.2527906,11.4582901 L25.2527906,3.71231615 L23.9654941,3.71231615 C23.3884301,3.71231615 22.9371365,3.53105888 22.6116132,3.16854434 C22.28609,2.8060298 22.1233283,2.37323183 22.1233283,1.87015043 C22.1233283,1.36706903 22.28609,0.934271057 22.6116132,0.571756517 C22.9371365,0.209241977 23.3884301,0.0279847072 23.9654941,0.0279847072 L30.1578343,0.0279847072 C30.7348982,0.0279847072 31.1861918,0.209241977 31.5117151,0.571756517 C31.8372384,0.934271057 32,1.36706903 32,1.87015043 C32,2.37323183 31.8372384,2.8060298 31.5117151,3.16854434 C31.1861918,3.53105888 30.7348982,3.71231615 30.1578343,3.71231615 L28.9149273,3.71231615 Z" id="Path"></path>
        </g>
    </g>
  </svg>
);

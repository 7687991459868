import { Text, Container, Row, Col, Spacer } from '@nextui-org/react';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import HALO from 'vanta/dist/vanta.halo.min.js';

const Hero = () => {
  const myRef = useRef(null)

  useEffect(() => {
    if (!myRef.current) return
    const vantaEffect = HALO({
      el: myRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      baseColor: 0xa4900d,
      backgroundColor: 0x0,
      amplitudeFactor: 0.10,
      xOffset: 0,
      yOffset: 0.2,
      size: 0.20
    })
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [myRef])

  return (
    <div ref={myRef} style={{ width: "100%", height: "70vh", position: 'relative'}}>
      <Container fluid responsive
      style={{
        height: '70vh' ,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 0,
        maxWidth: 'none',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Row justify="center" style={{ zIndex: 1,}}>
          <Col>
            <Text h1 css={{textGradient: "45deg, $cyan500 -20%, $white 100%"}}>Making apps</Text>
            <Text h3 css={{textGradient: "45deg, $green600 -20%, $white 100%"}}>since 2015</Text>
            <Spacer y={1.5} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;

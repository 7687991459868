import Hero from '../components/Hero.js';
import { Helmet } from "react-helmet";

const Home = () => (
  <>
  <Helmet>
      <title>Home | YVT</title>
      <meta name="YVT" content="Yellow Viking Technologies" />
    </Helmet>
    <Hero />
  </>
);

export default Home;

import { Text, Container, Spacer, Grid } from "@nextui-org/react";
import { Helmet } from "react-helmet";

const MomsMatePrivacyPolicy = () => (
  <>
  <Helmet>
      <title>MomsMate | YVT</title>
      <meta name="MomsMate" content="Your AI companion to product labels." />
    </Helmet>
  <Spacer y={6} />
    <Container xs>
      <Grid.Container gap={2}>
        <Grid xs={24}>
          <Text h1 align="left">MomsMate Privacy Policy
</Text>
        </Grid>
        <Spacer y={1} />
        <Grid xs={24} lg={14}>
          <Text align="left">
          Last updated: June 3, 2023
<Spacer y={1} />
This Privacy Policy explains how MomsMate ("we," "us," or "our") collects, uses, discloses, and protects your information when you use our mobile application (the "App").
<Spacer y={1} />
Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access or use the App.
<Spacer y={1} />
1. Personal Data Collection
<Spacer y={1} />
The personal data we collect includes the photos you upload to our App and data collected through Google Analytics. We do not collect any other personal information unless you voluntarily provide it to us.
<Spacer y={1} />
2. How We Use Your Information
<Spacer y={1} />
We use the photos you upload exclusively for the purpose of processing them through Google's Optical Character Recognition (OCR) and Artificial Intelligence (AI) services. This processing is done to extract product label information and provide you with details about the product.
<Spacer y={1} />
We use data collected through Google Analytics to understand user behavior and improve our App.
<Spacer y={1} />
We do not use your photos for any other purposes, and we do not share your photos with any third parties, other than Google for the specific purpose mentioned above.
<Spacer y={1} />
3. Data Retention
<Spacer y={1} />
We retain your uploaded photos only for as long as necessary to complete the OCR and AI processing. Once this processing is complete, your photos are immediately deleted from our systems. We do not store your photos on our servers or back them up in any way.
<Spacer y={1} />
Data collected through Google Analytics is retained according to Google's data retention policies.
<Spacer y={1} />
4. Third-Party Services
<Spacer y={1} />
We use Google's OCR and AI services to process your photos and Google Analytics to understand user behavior. By using our App, you consent to the processing of your photos and data by Google in accordance with their privacy policy. We encourage you to read Google's privacy policy to understand how they handle your data.
<Spacer y={1} />
5. Data Security
<Spacer y={1} />
We take the protection of your data seriously. We have implemented suitable security measures to protect your photos and user data from accidental loss and unauthorized access, use, alteration, and disclosure.
<Spacer y={1} />
6. Changes to This Privacy Policy
<Spacer y={1} />
We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make material changes to this Privacy Policy, we will notify you through a notice on the App or by other reasonable means.
<Spacer y={1} />
7. Contact Us
<Spacer y={1} />
If you have any questions or concerns about this Privacy Policy, please contact us at pk@yellowviking.com.
<Spacer y={1} />
By using the MomsMate App, you are confirming that you have read, understood, and agree to our Privacy Policy.
</Text>
</Grid>
        <Spacer y={10} />
      </Grid.Container>
    </Container>
  </>
);

export default MomsMatePrivacyPolicy;

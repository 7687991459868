import { NavLink } from "react-router-dom";
import { Navbar } from "@nextui-org/react";
import { YVTLogo } from "./YVTLogo";

function SiteNavbar() {
  return (
    <Navbar variant="sticky" maxWidth="fluid">
      <NavLink to="/" exact>
        <Navbar.Brand>
          <YVTLogo />
        </Navbar.Brand>
      </NavLink>
      <Navbar.Content>
        <NavLink to="/contact" activeClassName="active">
          Contact
        </NavLink>
      </Navbar.Content>
    </Navbar>
  );
}

export default SiteNavbar;
